import React from "react"
import Layout from "../components/Layout"
import macBookImage from "../images/Skills-ScreenCraft.svg"

export default () => {
    return (
        <Layout>
            <main className="container pt-8 mb-12 mx-auto flex flex-wrap flex-col items-center">

                <div className="flex flex-col w-full justify-center overflow-y-hidden">
                    <h1 className="my-4 text-6xl text-white font-bold leading-tight text-center">
                        Skills for the Future
                    </h1>

                    <p className="leading-normal text-base md:text-xl mb-2 text-center">
                        Discover the best training for your current and future employment.
                    </p>
                </div>

                <div className="w-full p-12 overflow-hidden">
                    <a href='https://northmetrotafe.wa.edu.au'>
                        <img className="mx-auto w-full md:w-10/12 transform -rotate-6 transition hover:scale-110 duration-1000 ease-in-out hover:rotate-6"
                         src={macBookImage}
                         alt="MacBook"/>
                    </a>
                </div>

                <div className="mx-auto md:pt-16">
                    <p className="text-gray-400 font-bold pb-8 lg:pb-6 text-center">
                        <a href='https://northmetrotafe.wa.edu.au' className="">Find out more about our courses at North Metropolitan TAFE</a>
                    </p>

                </div>
            </main>
        </Layout>
    )
}
